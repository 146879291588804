<template>
  <div>
    <v-row>
      <v-col>
        <summary-card
          :labels="orderSummary.graphData.sparklineTotal.labels"
          :values="orderSummary.graphData.sparklineTotal.values"
          :total="orderSummary.totalDocuments"
        ></summary-card>
      </v-col>
      <v-col>
        <v-card>
          <v-card-text>
            <p>Total Orders</p>
            {{orderSummary.ordersCount}}
          </v-card-text>
        </v-card>
      </v-col>
     
      <v-col>
        <v-card>
          <v-sheet
            class="v-sheet--offset mx-auto"
            color="cyan"
            elevation="12">
            <v-sparkline
              color="white"
              line-width="2"
              padding="8"
              label-size="16"></v-sparkline>
          </v-sheet>
          <v-card-text>
            <p>Last Month Orders</p>
            {{orderSummary.lastMonthOrdersCount}}
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-card dark :color="bg_col">
      <v-card-title>
        <v-layout>
          <v-flex xs12 sm6>
            <h3 class="title font-weight-light">Orders | <span class="grey--text">{{monthTxt}}</span></h3>
          </v-flex>
          <v-flex xs12 offset-sm3 sm3>
            <v-dialog
              ref="dialog"
              v-model="modal"
              :return-value.sync="month"
              width="290px">
              <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="month"
                label="Date"
                v-bind="attrs"
                v-on="on"></v-text-field>
              </template>
              <v-date-picker
                v-model="month"
                type="month"
                :color="bg_col">
                <v-spacer></v-spacer>
                <v-btn dark :color="bg_col" @click="modal = false">Cancel</v-btn>
                <v-btn dark :color="bg_col" @click="setMonth(month)">OK</v-btn>
              </v-date-picker>
            </v-dialog>
          </v-flex>
        </v-layout>
      </v-card-title>
      <line-chart 
        :data="orderSummary.graphData.ordersByDate"
        :colors="[text_col]"
        :library="{ 
          scales: {
            xAxes: [
              {
                gridLines: {
                  display: false,
                  drawBorder: false //<- set this
                },
                ticks: {
                  fontColor: text_col
                }
              }
            ],
            yAxes: [
              {
                gridLines: {
                  display: false,
                  drawBorder: false //<- set this
                },
                ticks: {
                  fontColor: text_col
                }
              }
            ]
          }
        }"></line-chart>
    </v-card>
  </div>
</template>

<script>
  import { gql } from 'apollo-boost'
  import SummaryCard from '@/components/Shared/Cards/Summary'
  export default {
    components: {
      SummaryCard,
    },
    data() {
      return {
        bg_col: "#43434c",
        text_col: "#c79477",
        modal: false,
        month: new Date().toISOString().substr(0, 7),
        monthTxt: this.$moment(this.month).format("MMMM"),
        date: Date.now(),
        createdAtRange: {
          gte: this.$moment().startOf('month').unix(),
          lte: this.$moment().endOf('month').unix()
        },
        orderSummary: {
          totalAmount: 0,
          ordersCount: 0,
          lastWeekOrdersCount: 0,
          lastMonthOrdersCount: 0,
          totalDocuments: 0,
          incompleteOrders: 0, // paid but survey not completed
          surveysCompleted: 0,
          purchasesData: [],
          graphData: {
            ordersByDate: {},
            sparklineWeek: [],
            sparklineTotal: {
              labels: [],
              values: []
            }
          }
        }
      }
    },
    apollo: {
      orders: {
        query: gql`query Orders($limit: Int! $skip: Int! $query: JSON!) {
          orders(limit: $limit, skip: $skip, query: $query) {
            records {
              id
              totalAmount
              products
              createdAt
              updatedAt
            }
            count
          }
        }`,
        variables() {
          const self = this
          return {
            limit: 9999,
            skip: 0,
            query: {
              payment: "complete"
            }
          }
        },
        result() {
          var self = this
          const orders = this.orders.records.filter(item => item.createdAt/1000 >= this.createdAtRange.gte && item.createdAt/1000 <= this.createdAtRange.lte)

          if (orders.length) {
            this.orderSummary.totalAmount = orders.map(item => item.totalAmount).reduce((a,b)=>a+b)
            this.orderSummary.ordersCount = this.orders.count
            this.orderSummary.lastWeekOrdersCount = orders.filter(item => item.createdAt/1000 > this.$moment().days(-7).unix()).length
            this.orderSummary.lastMonthOrdersCount = orders.filter(item => item.createdAt/1000 > this.$moment().days(-31).unix()).length
            this.orderSummary.purchasesData = _.groupBy(orders, item => self.$moment.unix(item.createdAt/1000).format("YYYY-MM-DD"))
            
            const entries = Object.entries(this.orderSummary.purchasesData).reverse()
            this.orderSummary.graphData.sparklineTotal.labels = []
            this.orderSummary.graphData.sparklineTotal.values = []
            for (const [date, values] of entries) {
              this.orderSummary.graphData.sparklineTotal.labels.push(self.$moment(date).format("DD MMM"))
              this.orderSummary.graphData.sparklineTotal.values.push(values.length)
            }
            
            this.setDocCountSummary()
            
            this.orderSummary.totalDocuments = orders.map(val => val.products.length).reduce((a,b) => a+b)
  
            this.orderSummary.graphData.ordersByDate = {}
            
            // const dim = this.$moment().daysInMonth()
            // for (var i = 1; i <= dim; i++) {
            //   const currentDay = this.$moment(this.$moment().year()+'-'+(this.$moment().month() + 1)+'-'+i).format("YYYY-MM-DD")
            //   this.orderSummary.graphData.ordersByDate[currentDay] = 0
            // }
  
            // for (const [date, value] of Object.entries(this.orderSummary.purchasesData)) { 
            //   this.orderSummary.graphData.ordersByDate[date] = value.length
            // }
            this.setOrdersByDate()
          } else {
            this.orderSummary.graphData.ordersByDate = {}
            this.setOrdersByDate()
          }
          
        }
      }
    },
    methods: {
      setMonth(month) {
        this.$refs.dialog.save(month)
        this.monthTxt = this.$moment(month).format("MMMM")
        this.createdAtRange = {
          gte: this.$moment(month).startOf('month').unix(),
          lte: this.$moment(month).endOf('month').unix()
        }
        this.$apollo.queries.orders.refetch()
      },

      setDocCountSummary() {
        const entries = Object.entries(this.orderSummary.purchasesData).reverse()
        this.orderSummary.graphData.sparklineTotal.labels = []
        this.orderSummary.graphData.sparklineTotal.values = []
        for (const [date, values] of entries) {
          this.orderSummary.graphData.sparklineTotal.labels.push(this.$moment(date).format("DD MMM"))
          this.orderSummary.graphData.sparklineTotal.values.push(values.length)
        }
      },

      setOrdersByDate() {
        const dim = this.$moment().daysInMonth()
        for (var i = 1; i <= dim; i++) {
          const currentDay = this.$moment(this.$moment().year()+'-'+(this.$moment().month() + 1)+'-'+i).format("YYYY-MM-DD")
          this.orderSummary.graphData.ordersByDate[currentDay] = 0
        }

        for (const [date, value] of Object.entries(this.orderSummary.purchasesData)) { 
          this.orderSummary.graphData.ordersByDate[date] = value.length
        }
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>