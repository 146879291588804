var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('summary-card',{attrs:{"labels":_vm.orderSummary.graphData.sparklineTotal.labels,"values":_vm.orderSummary.graphData.sparklineTotal.values,"total":_vm.orderSummary.totalDocuments}})],1),_c('v-col',[_c('v-card',[_c('v-card-text',[_c('p',[_vm._v("Total Orders")]),_vm._v(" "+_vm._s(_vm.orderSummary.ordersCount)+" ")])],1)],1),_c('v-col',[_c('v-card',[_c('v-sheet',{staticClass:"v-sheet--offset mx-auto",attrs:{"color":"cyan","elevation":"12"}},[_c('v-sparkline',{attrs:{"color":"white","line-width":"2","padding":"8","label-size":"16"}})],1),_c('v-card-text',[_c('p',[_vm._v("Last Month Orders")]),_vm._v(" "+_vm._s(_vm.orderSummary.lastMonthOrdersCount)+" ")])],1)],1)],1),_c('v-card',{attrs:{"dark":"","color":_vm.bg_col}},[_c('v-card-title',[_c('v-layout',[_c('v-flex',{attrs:{"xs12":"","sm6":""}},[_c('h3',{staticClass:"title font-weight-light"},[_vm._v("Orders | "),_c('span',{staticClass:"grey--text"},[_vm._v(_vm._s(_vm.monthTxt))])])]),_c('v-flex',{attrs:{"xs12":"","offset-sm3":"","sm3":""}},[_c('v-dialog',{ref:"dialog",attrs:{"return-value":_vm.month,"width":"290px"},on:{"update:returnValue":function($event){_vm.month=$event},"update:return-value":function($event){_vm.month=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Date"},model:{value:(_vm.month),callback:function ($$v) {_vm.month=$$v},expression:"month"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('v-date-picker',{attrs:{"type":"month","color":_vm.bg_col},model:{value:(_vm.month),callback:function ($$v) {_vm.month=$$v},expression:"month"}},[_c('v-spacer'),_c('v-btn',{attrs:{"dark":"","color":_vm.bg_col},on:{"click":function($event){_vm.modal = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"dark":"","color":_vm.bg_col},on:{"click":function($event){return _vm.setMonth(_vm.month)}}},[_vm._v("OK")])],1)],1)],1)],1)],1),_c('line-chart',{attrs:{"data":_vm.orderSummary.graphData.ordersByDate,"colors":[_vm.text_col],"library":{ 
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
                drawBorder: false //<- set this
              },
              ticks: {
                fontColor: _vm.text_col
              }
            }
          ],
          yAxes: [
            {
              gridLines: {
                display: false,
                drawBorder: false //<- set this
              },
              ticks: {
                fontColor: _vm.text_col
              }
            }
          ]
        }
      }}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }