<template>
  <v-card
    class="mx-auto">
    <v-sheet
      class="v-sheet--offset mx-auto"
      :color="bg_col"
      elevation="12"
      max-width="calc(100% - 32px)">
      <v-sparkline
        :labels="labels"
        :value="values"
        :color="text_col"
        line-width="2"
        padding="24"
        stroke-linecap="round"
        smooth
        label-size="12"
      ></v-sparkline>
    </v-sheet>
    <v-card-text
      class="pt-0">
      <div class="title font-weight-light mb-2">
        Total Documents Sold
      </div>
      <div class="subheading font-weight-light grey--text">
        {{total}}
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
  export default {
    props: {
      labels: {
        type: Array,
        default: []
      },
      values: {
        type: Array,
        default: []
      },
      total: {
        type: Number,
        default: 0
      }
    },
    data() {
      return {
        bg_col: "#43434c",
        text_col: "#c79477"
      }
    },
  }
</script>

<style lang="scss" scoped>
.v-sheet--offset {
  top: -24px;
  position: relative;
}
</style>